import React from "react"
import get from 'lodash/get'
import filter from 'lodash/filter'
import { graphql } from 'gatsby'
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import * as sections from "../components/sections"

const BecomeMentorPageTemplate = ({ pageContext, data, location }) => {
  const seoData = get(data, 'sanityMentorSignupPage.seo')
  const contentData = get(data, 'sanityMentorSignupPage.content') || []
  const footerData = get(data, 'sanityFooter')

  const [becomeMentorContactSection] = filter(contentData, { '_type': 'becomeMentorContactSection' })

  const focusKeyword = get(seoData, 'seoTools.focus_keyword')
  const synonyms = get(seoData, 'seoTools.focus_synonyms') || []

  return (
    <>
      <SEO
        title={get(seoData, 'seoTools.seo_title')}
        description={get(seoData, 'seoTools.meta_description')}
        lang={get(pageContext, 'locale')}
        pathname={get(location, 'pathname')}
        image={get(seoData, 'seoThumbnail.asset.localFile.childImageSharp.resize')}
        keywords={[focusKeyword, ...synonyms]}
      />
      <Layout locale={get(pageContext, 'locale')} footer footerContent={footerData}>
        <sections.BecomeMentorContactSection content={becomeMentorContactSection} />
        <sections.MentorGrid content={{
          headline: get(becomeMentorContactSection, 'mentorsHeadline'),
          description: get(becomeMentorContactSection, 'mentorsDescription'),
        }} locale={get(pageContext, 'locale')} />
      </Layout>
    </>
  )
}

export default BecomeMentorPageTemplate

export const query = graphql`
  query BecomeMentorPage($slug: String, $locale: String) {
    sanityMentorSignupPage(essentials: {slug: {current: {eq: $slug}}, locale: {eq: $locale}}) {
      seo {
        seoThumbnail {
          asset {
            localFile {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        seoTools {
          focus_keyword
          focus_synonyms
          meta_description
          seo_title
        }
      }
      essentials {
        locale
        slug {
          current
        }
      }
      content {
        ... on SanityBecomeMentorContactSection {
          _key
          _type
          headline
          _rawDescription
          email {
            type
            placeholder
            label
            identifier
            errors {
              _key
              key
              message
            }
          }
          howDidYouHearAboutUs {
            type
            placeholder
            label
            identifier
            modifier
            errors {
              _key
              key
              message
            }
          }
          primaryButtonText
          toastSuccess {
            headline
            description
          }
          toastError {
            headline
            description
          }
          mentorsHeadline
          mentorsDescription
          hero {
            asset {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1567) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    sanityFooter(locale: {eq: $locale}) {
      copyright
      languageSwitcherLabel
      mission
      links {
        _key
        headline
        links {
          _key
          label
          slug
        }
      }
      newsletter {
        headline
        subline
        buttonText
        emailField {
          type
          placeholder
          label
          identifier
          errors {
            _key
            key
            message
          }
        }
        postSubmitRedirectSlug
        toastError {
          headline
          description
        }
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
